<template>
  <div>
    <div class="mb-2 text-center">
      CONTRATO PRIVADO DE COMPRA-VENTA N° {{ contrato.numero_venda }}
    </div>
    <div class="mb-2">
      En Ciudad de Ciudad del Este, República del Paraguay, a los
      {{ contrato.dia_emissao }} dias del mes de {{ contrato.mes_emissao }} del
      AÑO {{ contrato.ano_emissao }}, por una parte, el Señor JOSE HORTOLAM JR,
      brasilero, soltero, con Cédula de Identidad Nº. 6.337.158, quien asiste al
      acto en nombre y representación de POTABLE S.A., con RUC. Nº. 80069729-4
      en su carácter de PRESIDENTE de la misma, domiciliado en Ciudad del Este,
      que en adelante se denominará EL VENDEDOR, y por la otra parte de
      {{ contrato.cliente_genero }} {{ contrato.cliente_nome }},
      {{ contrato.cliente_nacionalidade }}, {{ contrato.cliente_estado_civil }},
      con {{ contrato.cliente_tipo_doc }}: {{ contrato.cliente_documento }},
      quien asiste al acto de la misma, domiciliado en la
      {{ contrato.cliente_endereco }}, que en adelante se denominará EL
      COMPRADOR Son mayores de edad, hábiles para contratar, de común acuerdo
      convienen en celebrar el presente CONTRATO PRIVADO DE COMPRA VENTA DE
      PISCINA, que se regirá por las cláusulas y condiciones siguientes.
      ---------------------------------------------------------------------------------------------------------------------------------
    </div>
    <div class="text-center mb-2">DEL OBJETO DEL PRESENTE CONTRATO</div>
    <div class="mb-2">
      CLAUSULA PRIMERA: El Señor JOSE HORTOLAM JR, en el carácter invocado DICE:
      Que, a través del presente documento, y bajo su absoluta responsabilidad.
      VENDE a favor de {{ contrato.cliente_genero }}
      {{ contrato.cliente_nome }}, una PISCINA MARCA
      {{ contrato.produto_marca }}, con código de identificación n°
      {{ contrato.numero_garantia }}, con los accesorios y características
      siguientes:
    </div>
    <div class="mb-2">{{ contrato.venda_items }}</div>
    <div class="mb-2">
      Características y Accesorios que el comprador declara conocer y aceptar
      expresamente por el presente contrato, teniendo en cuenta a las bases y
      condiciones del manual de construcción y procedimiento de instalación de
      los accesorios correspondientes.
      ---------------------------------------------------------------------
    </div>
    <div class="text-center mb-2">DE LA RESERVA DE PROPIEDAD</div>
    <div class="mb-2">
      CLAUSULA SEGUNDA: En virtud de la reserva de la propiedad, establecida en
      este instrumento, queda reservado al VENDEDOR el derecho de propiedad de
      los productos descriptos en la cláusula primera de este contrato, hasta la
      total cancelación o pago total de las cuotas establecidas por las partes.
      -----------------
    </div>
    <div class="text-center mb2">DE LA CONSERVACIÓN Y USO DEL BIEN</div>
    <div class="mb-2">
      CLAUSULA TERCERA: Mientras no estén totalmente pagadas las cuotas, EL
      COMPRADOR se obliga a velar por la conservación de los productos
      descriptos en la cláusula primera, proporcionando todos los recursos
      necesarios para la manutención y conservación de los mismos, siendo los
      gastos por cuenta del COMPRADOR, obligándose por el presente contrato a
      cuidar la apariencia y el correcto funcionamiento de los accesorios y el
      correspondiente motor instalado en la casa de máquinas, cuyas indicaciones
      de uso le son entregados por el presente
      instrumento.------------------------------------------------------------------------------------
    </div>
    <div class="text-center mb-2">DEL PRECIO</div>
    <div class="mb-2">
      CLAUSULA CUARTA: El precio de la venta fijan y acuerdan las partes en la
      suma de {{ contrato.venda_moeda }}
      <span v-if="contrato.venda_moeda == 'G$'">
        {{ contrato.venda_total | guarani }}
        {{ "(GUARANIES " + total_escrito + ")" }}
      </span>
      <span v-else>
        {{ contrato.venda_total | currency }}
        {{ "(DOLARES " + total_escrito + ")" }} </span
      >.
      <span v-if="contrato.entrega_inicial && contrato.venda_moeda == 'G$'">
        Con una entrega en fecha
        {{ contrato.data_entrega_inicial | dateFormat("dd/MM/yyyy") }} de
        {{ contrato.entrega_inicial | guarani }}
        {{ "(GUARANIES " + entrega_inicial_escrita + ")" }}.
      </span>
      <span v-if="contrato.entrega_inicial && contrato.venda_moeda != 'G$'">
        Con una entrega en fecha
        {{ contrato.data_entrega_inicial | dateFormat("dd/MM/yyyy") }} de
        {{ contrato.entrega_inicial | currency }}
        {{ "(DOLARES " + entrega_inicial_escrita + ")" }}.
      </span>
    </div>
    <div class="mb-2">
      Inc. 2. El comprador se obliga a abonar al vendedor el saldo de
      {{ contrato.venda_moeda }}
      <span v-if="contrato.venda_moeda == 'G$'">
        {{ this.total_sem_entrada_inicial | guarani }}
        {{ "(GUARANIES " + total_sem_entrega_inicial_escrito + " )" }}
      </span>
      <span v-else>
        {{ this.total_sem_entrada_inicial | currency }}
        {{ "(DOLARES " + total_sem_entrega_inicial_escrito + " )" }}</span
      >, pagadero de la siguiente forma: {{ contrato.parcelamento_texto }}
    </div>
    <div class="text-center mb-2">DE LA MORA</div>
    <div class="mb-2" v-if="contrato.possui_fiador">
      CLAUSULA QUINTA: {{ contrato.fiador_genero }} {{ contrato.fiador_nome }},
      {{ contrato.fiador_nacionalidade }}, {{ contrato.fiador_estado_civil }},
      con {{ contrato.fiador_tipo_doc }}: {{ contrato.fiador_documento }}, quien
      asiste al acto de la misma, domiciliado en la
      {{ contrato.fiador_endereco }}, quien firma también este contrato en
      prueba de conformidad, se constituye en Co-Deudor Solidario y pagador
      principal de todas las sumas que adeudare el Comprador, como asi también
      de todas las demás obligaciones que emanen del presente contrato,
      renunciando desde ya al beneficio de Exclusion, Esta fianza quedara
      subsistente hasta quedar extinguidas todas las obligaciones contraídas por
      el Comprador.
    </div>
    <div class="mb-2">
      <span v-if="!contrato.possui_fiador">CLAUSULA QUINTA:</span
      ><span v-else>CLAUSULA SEXTA:</span> En caso de mora o atraso en el pago
      de las cuotas establecidas en este contrato, el COMPRADOR se compromete y
      se obliga a abonar a la fecha de cancelación de su cuota atrasada, un
      interés moratorio de 2% (dos por ciento) mensual, sumada a la multa
      contractual de 2% (dos por ciento).
    </div>
    <div class="mb-2">
      <span v-if="!contrato.possui_fiador">CLAUSULA SEXTA:</span
      ><span v-else>CLAUSULA SEPTIMA:</span> En caso de producirse el atraso de
      una cuota por parte del COMPRADOR, en los plazos estipulados, hará decaer
      los demás plazos inmediatamente de la interpelación judicial o
      extrajudicial correspondiente, de la rescisión del presente contrato.
    </div>
    <div class="mb-2">
      <span v-if="!contrato.possui_fiador">CLAUSULA SEPTIMA:</span
      ><span v-else>CLAUSULA OCTAVA:</span> EL VENDEDOR igualmente podrá
      rescindir el presente contrato, de pleno derecho, independientemente de
      cualquier notificación, interpelación judicial o extrajudicial, si el
      COMPRADOR entra en estado de insolvencia o si es decretada su quiebra.
    </div>
    <div class="b-2">
      <span v-if="!contrato.possui_fiador">CLAUSULA OCTAVA:</span
      ><span v-else>CLAUSULA NOVENA:</span> En caso de rescisión del presente
      contrato, de este contrato, el COMPRADOR queda obligado a restituir los
      productos descriptos en la cláusula primera AL VENDEDOR, en buen estado de
      conservación, autorizando expresamente AL VENDEDOR a solicitar
      judicialmente la devolución del bien vendido y su respectivo secuestro,
      comprometiéndose por este instrumento a solventar los gastos judiciales,
      intereses, costos y costas, además de los honorarios profesionales
      generados por la citada diligencia.
    </div>
    CONDICIONES GENERALES
    <div class="text-center mb-2">CONDICIONES GENERALES</div>

    <div class="mb-2">
      <span v-if="!contrato.possui_fiador">CLAUSULA NOVENA:</span
      ><span v-else>CLAUSULA DECIMA:</span> No podrá el COMPRADOR ceder el bien
      objeto de este contrato a terceros, sin la debida autorización por escrito
      del VENDEDOR, ni constituir directa o indirectamente, prenda o caución o
      cualquier otro gravamen sobre el objeto de este contrato, mientras no esté
      totalmente saldada la obligación asumida por el comprador.
    </div>
    <div class="mb-2">
      <span v-if="!contrato.possui_fiador">CLAUSULA DECIMA:</span
      ><span v-else>CLAUSULA DECIMA PRIMERA:</span> EL VENDEDOR queda facultado
      a ejercer cualquier acción que derive el presente contrato a los efectos
      de precautelar sus derechos sobre el bien enajenado por el presente
      instrumento, debiendo para el efecto prever cualquier acción que pudiera
      impedir el ejercicio de sus derechos.
    </div>
    <div class="mb-2">
      <span v-if="!contrato.possui_fiador">CLAUSULA DECIMA PRIMERA:</span
      ><span v-else>CLAUSULA DECIMA SEGUNDA:</span> El presente contrato es
      celebrado en atención a lo prescripto por el código civil el cual las
      partes se someten, manifestando estar en conocimiento de las disposiciones
      del Derecho sucesorio, que hace referencia a las obligaciones asumidas en
      vida, siendo responsables los herederos y sucesores del deudo, ya sea
      título universal o singular, frente a los acreedores.
    </div>
    <div class="mb-2">
      <span v-if="!contrato.possui_fiador">CLAUSULA DECIMA SEGUNDA:</span
      ><span v-else>CLAUSULA DECIMA TERCERA:</span> Para todos los efectos
      derivados del presente contrato, inclusive notificaciones privadas o
      judiciales, el Comprador fija domicilio especial en {{ contrato.cidade }},
      donde se tendrán por validas todas las notificaciones como si hubiesen
      sido practicadas personalmente.
    </div>
    <div class="mb-2">
      <span v-if="!contrato.possui_fiador">CLAUSULA DECIMA TERCERA:</span
      ><span v-else>CLAUSULA DECIMA CUARTA:</span> El comprador declara bajo fe
      de juramente que el inmueble individualizado como {{ contrato.imovel }},
      lugar donde se instalara la piscina es de su propiedad.
    </div>
    <div class="text-center mb-2">
      DE LAS INFORMACIONES GENERALES ACERCA DE LOS PRODUCTOS
    </div>
    <div class="mb-2">
      <span v-if="!contrato.possui_fiador">CLAUSULA DECIMA CUARTA:</span
      ><span v-else>CLAUSULA DECIMA QUINTA:</span> El COMPRADOR declara conocer
      y aceptar las siguientes informaciones respecto a los productos
      adquiridos, además de aquellas que constan en el Certificado de Garantía y
      manual de cada producto:
    </div>
    <div class="mb-2">
      -La piscina cuenta con 5 años de garantía en el casco de la misma, contra
      agujeros y/o perdidas o fuga de agua. La garantía no cubre: pies de
      gallina (rayaduras estéticas) en el gel, daños hechos por terceros,
      perdidas de agua no relacionadas a la estructura, accesorios (luces,
      calentadores) u otros equipos (motor, filtro). Cada aparato de la piscina
      tiene su garantía individual segun estipulado por el fabricante en el
      manual del producto.
    </div>
    <div class="mb-2">
      -La empresa provee 1 año de garantía de los productos relacionados con la
      piscina al momento de la compra: filtro, motor, luces, cascadas, etc.
      independiente de la garantía estipulada de la fábrica.
    </div>
    <div class="mb-2">
      -En caso de falta de pago, o incumplimiento por parte del cliente, la
      empresa no estará obligada a proveer servicios de reparación o asistencia
      en los equipos y/o piscina, hasta que el cliente se ponga al día con el
      pago de las cuotas (desde que este dentro del período de garantía); las
      asistencias solicitadas fuera del período de garantía pueden generar
      costos de mano de obra.
    </div>
    <div class="mb-2">
      -La empresa no cubre garantía por daños ocasionados en la piscina por
      terceros, por ejemplo: La colocación de pisos daña el borde de la piscina;
      La empresa no cubre garantía por equipos o materiales comprados por el
      cliente que puede tener desperfecto, por ejemplo, caños de baja calidad
      que vengan a romper, otros equipos que puedan dañar la piscina como
      manchas ocasionadas por ionizadores, etc.
    </div>
    <div class="mb-2">
      -La empresa ni la fábrica cubren garantía por el mal manejo de los equipos
      y/o mal manejo de productos químicos en la piscina, por ejemplo: exceso de
      cloro que puede dejar la piscina de fibra blanca, uso de ionizadores u
      otros aparatos que puedan manchar la piscina.
    </div>
    <div class="mb-2">
      - Una vez instalada la piscina, el cliente se compromete a instalar el
      contra piso alrededor de la misma en un plazo máximo de 3 días, la
      inobservancia de esta conlleva la supresión de la garantía de la piscina,
      y caso la piscina salga de nivel a consecuencia de esta, se cobrará para
      la reinstalación de la piscina; El cliente se compromete a instalar el
      contrapiso de la piscina con pendientes de mínimo 2% en cada lado para que
      el agua que caiga en el piso siempre corra para fuera de la piscina
    </div>
    <div class="text-center mb-2">INDICACIONES</div>
    <div class="mb-2">
      -La piscina no podrá ser vaciada sin el acompañamiento de un técnico
      especializado.
    </div>
    <div class="mb-2">
      -Puede ocurrir la entrada de aire en la tabulación, situación está que no
      cubre la garantía legal o contractual.
    </div>
    <div class="mb-2">
      -Con relación al motor de la casa de máquina, deben ser tomados los
      siguientes cuidados, bajo pena de perdida de la garantía legal o
      contractual:
    </div>
    <div class="mb-2 ml-4">
      * No dejar la tapa de la casa de maquina abierta o semiabierta, lo que
      podrá inundar, mojar o mismo humectar los equipamientos con el roció o
      lluvia
    </div>
    <div class="mb-2 ml-4">
      * No hacer funcionar el motor con los registros cerrados.
    </div>
    <div class="mb-2 ml-4">
      * No dejar el motor encendido con la manguera de aspiración llena de aire.
    </div>
    <div class="mb-2">
      • En caso de problemas con el motor, el cliente debe buscar directamente
      la asistencia técnica especializada.
    </div>
    <div class="text-center mb-2">DE LA JURISDICCIÓN CLAUSULA</div>
    <div class="mb-2">
      <span v-if="!contrato.possui_fiador">CLAUSULA DECIMA QUINTA:</span
      ><span v-else>CLAUSULA DECIMA SEXTA:</span> Para dirimir cualquier
      controversia oriunda del CONTRATO, las partes se someten a la jurisdicción
      de los Juzgados y Tribunales competentes de la Jurisdicción del Alto
      Paraná, con exclusión de cualquier otra jurisdicción que les pudiera
      corresponder.
    </div>
    <div class="mb-2">
      <span v-if="!contrato.possui_fiador">CLAUSULA DECIMA SEXTA:</span
      ><span v-else>CLAUSULA DECIMA SEPTIMA:</span> El comprador en forma
      irrevocable, faculta para que en el caso de un atraso superior a noventa
      (90) días en el pago del presente crédito o de cualquier otra deuda
      pendiente que mantenga con nuestra empresa, incluya mi nombre personal o
      razón social que represento, en el registro general de morosos de
      INFORMCONF. Esta autorización se extiende a fin de que pueda proveerse la
      información a terceros interesados.
    </div>
    <div class="mb-6">
      BAJO TALES CLÁUSULAS Y CONDICIONES queda formalizado el presente contrato
      a cuyo fiel cumplimiento se obligan las partes conforme y con arreglo a
      derecho, firman en prueba de conformidad en dos ejemplares de un mismo
      tenor y a un solo efecto en el mismo lugar y fecha de su otorgamiento.
    </div>
    <div class="d-flex justify-space-between">
      <div class="text-center">
        <div>…………………………….................................</div>
        <div>POTABLE S.A.</div>
        <div>RUC: 80069729-4</div>
        <div>VENDEDOR</div>
      </div>
      <div class="text-center" v-if="contrato.possui_fiador">
        <div>…………………………….................................</div>
        <div>{{ contrato.fiador_nome }}</div>
        <div>
          {{ contrato.fiador_tipo_doc }}: {{ contrato.fiador_documento }}
        </div>
        <div>CO-DEUDOR</div>
      </div>
      <div class="text-center">
        …………………………….................................
        <div>{{ contrato.cliente_nome }}</div>
        <div>
          {{ contrato.cliente_tipo_doc }}: {{ contrato.cliente_documento }}
        </div>
        <div>COMPRADOR</div>
      </div>
    </div>
  </div>
</template>

<script>
import writtenNumber from "written-number";
export default {
  name: "ContratoTexto",

  props: {
    contrato: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      total_escrito: "",
      entrega_inicial_escrita: "",
      total_sem_entrega_inicial_escrito: "",
    };
  },

  computed: {
    total_sem_entrada_inicial() {
      let valor_inicial = 0;
      if (this.contrato.entrega_inicial) {
        valor_inicial = this.contrato.entrega_inicial;
      }
      return this.contrato.venda_total - valor_inicial;
    },
  },

  mounted() {
    if (this.contrato) {
      this.contrato.dia_emissao = writtenNumber(this.contrato.dia_emissao, {
        lang: "es",
      });
      this.contrato.ano_emissao = writtenNumber(this.contrato.ano_emissao, {
        lang: "es",
      });
      this.total_escrito = writtenNumber(Number(this.contrato.venda_total), {
        lang: "es",
      }).toUpperCase();
      if (
        Number(this.contrato.venda_total) > 1000000 &&
        Number(this.contrato.venda_total) < 2000000
      ) {
        this.total_escrito = this.total_escrito.substring(3);
        this.total_escrito = "UN" + this.total_escrito;
      }
      if (this.contrato.entrega_inicial) {
        this.entrega_inicial_escrita = writtenNumber(
          Number(this.contrato.entrega_inicial),
          {
            lang: "es",
          }
        ).toUpperCase();
        this.total_sem_entrega_inicial_escrito = writtenNumber(
          Number(this.contrato.venda_total - this.contrato.entrega_inicial),
          {
            lang: "es",
          }
        ).toUpperCase();
      }
    }
  },
};
</script>

<style></style>
